.nm {
  margin: 0px;
}
.np {
  padding: 0px;
}
.solarlogo {
  width: 100%;
}
.main__container {
  background-color: #040e18;
  padding: 0px;
  margin: 0px;
}
.menu__nav {
  background-color: #040e18;
  padding: 0px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid rgb(255 255 255 / 10%); */
  z-index: 0;
}
.menu__nav.col {
  margin: 0px;
  padding: 20px 0px;
}

.main__nav__links a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  display: block;
  line-height: 1;
  position: relative;
  padding: 50px 20px;
  z-index: 1;
  cursor: pointer;
  letter-spacing: 1px;
  border-bottom: #040e18 2px solid;
}
.main__nav__links a:hover {
  border-bottom: #00c4f4 2px solid;
  color: #00c4f4;
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link.active {
  color: #fff;
}

.a.nav-link a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  border-bottom: 2px solid #00c4f4;
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}

.main__nav__links.active > a::before,
.main__nav__links.active > a:hover::before {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.menu-area {
  border-bottom: 1px solid rgb(255 255 255 / 10%);
}
.menu-nav {
  background-color: #040e18;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.navbar-wrap {
  display: flex;
  flex-grow: 1;
}
.navbar-wrap ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 140px;
}
.navbar-wrap > ul > li {
  display: block;
  position: relative;
  margin-right: 65px;
}
.navbar-wrap > ul > li > a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
  padding: 45px 0;
  display: block;
  line-height: 1;
  position: relative;
  z-index: 1;
  letter-spacing: 1px;
}
.navbar-wrap > ul > li:last-child {
  margin-right: 0;
}
.navbar-wrap > ul > li > a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #00c4f4;
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}
.navbar-wrap > ul > li.active > a::before,
.navbar-wrap > ul > li > a:hover::before {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.navbar-wrap > ul > li.active > a,
.navbar-wrap > ul > li:hover > a {
  color: #fff;
}
.main-menu .navigation li.menu-item-has-children .dropdown-btn {
  display: none;
}
.header-action > ul {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.header-action > ul > li {
  position: relative;
  margin-left: 65px;
  padding-left: 65px;
}
.header-action > ul > li:first-child {
  margin-left: 0;
  padding-left: 0;
}
.header-action > ul > li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 28px;
  background: #ffffff;
  opacity: 0.2;
}
.header-action > ul > li:first-child::before {
  display: none;
}

a.btn {
  font-size: 16px;
  padding: 16px 35px;
}
.navbar-wrap ul li .sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  min-width: 230px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  background: #0b1d33;
  margin: 0;
  transform: scale(1, 0);
  transform-origin: 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0;
  padding: 18px 0;
  display: block;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
}
.navbar-wrap ul li .sub-menu .sub-menu {
  right: auto;
  left: 100%;
  top: 0;
}
.navbar-wrap ul li .sub-menu li {
  margin-left: 0;
  text-align: left;
  display: block;
}
.navbar-wrap ul li .sub-menu li a {
  padding: 9px 15px 9px 25px;
  line-height: 1.4;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: block;
  font-size: 14px;
}
.navbar-wrap ul li .sub-menu li a:hover,
.navbar-wrap ul li .sub-menu li.active a {
  color: #00c4f4;
}
.navbar-wrap ul li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.sticky-menu {
  position: fixed;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  z-index: 99;
  background: #030b15;
  -webkit-animation: 3000ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown !important;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  border-radius: 0;
}
.sticky-menu .navbar-wrap > ul > li > a {
  padding: 45px 0;
}
.header-fixed-height .active-height {
  display: block;
  height: 105px;
}
@media screen and (max-width: 768px) {
  a.btn {
    font-size: 14px;
    padding: 15px !important;
  }
  .mobi-menu {
    margin: auto;
    top: 0;
    width: 100%;
    z-index: 99;
    background: #030b15;
    -webkit-animation: 3000ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown !important;
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    border-radius: 0;
  }
  .menu__nav.col {
    margin: 0px;
    padding: 20px;
  }
}
