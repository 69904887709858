.about__main__wrap {
  background-color: #040e18;
  padding: 100px;
  justify-content: center;
  align-items: center;
}

.about__row {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}

.about__row .title {
  font-weight: 600;
  font-size: 42px;
  margin-bottom: 0;
  letter-spacing: -0.01em;
  margin-bottom: 30px;
  width: 70%;
}
.about__row .title span {
  font-size: 42px;
  font-weight: 600;
  color: #00c4f4;
}

.about__row h3 {
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  letter-spacing: 0.09em;
  display: inline-block;
  padding: 0 15px;
  margin-bottom: 25px;
}

.about__row h3::before {
  content: '';
  margin-top: 5px;
  left: 0;
  align-items: center;
  background-color: #00c4f4;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-20%);
}
.about__row h3::after {
  content: '';
  margin-top: 5px;
  right: 0;
  align-items: center;
  background-color: #00c4f4;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  transform: translateY(-20%);
}

.about__row p {
  width: 70%;
  color: #a4b4c3;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.75;
}

.about__row .float__img {
  position: absolute;
  left: 0px;
  top: 0px;
}

@media (max-width: 767.98px) {
  .about__main__wrap {
    background-color: #040e18;
    padding: 20px 10px;
    justify-content: center;
    align-items: center;
  }
  .about__row .float__img {
    position: absolute;
    left: 0px;
    top: 0px;
    display: none;
  }
  .about__row {
    margin: 0px auto;
    text-align: center;
  }
  .about__row .big__img {
    width: 250px;
  }
  .about__row .title {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 0;
    line-height: 50px;
    letter-spacing: -0.01em;
    margin: 30px 0px;
    width: 100%;
    text-align: center;
  }
  .about__row .title span {
    font-size: 30px;
    font-weight: 600;
    color: #00c4f4;
  }
  .about__row p {
    width: 100%;
    color: #a4b4c3;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.75;
  }
}
