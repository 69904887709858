.herosection__container {
  padding: 50px;
  background-color: #030b15;
  background-image: url('../../assets/Images/banner/banner_bg.jpg');
  display: flex;
  justify-content: center;
  background-position: top center;
  background-size: contain;
  text-align: center;
  background-repeat: no-repeat;
  min-height: auto;
  align-items: center;
  position: relative;
  z-index: 1;
}
.off-grid{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2)), rgba(255, 255, 255, 0.2);
    padding: 10px;
    border-radius: 10px;
}
.herosection__container::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0.23deg,
    #030b15 5.68%,
    rgba(3, 11, 21, 0.42) 81.9%
  );
  z-index: -1;
}

.solar__nft__icon {
  width: 28px;
  border-radius: 4px;
  border: 1px solid #000;
  margin-left: 10px;
  cursor: pointer;
  margin-top: -5px;
}
.solar__nft__sml {
  width: 400px;
  z-index: 9999;
  border-radius: 10px;
}
.image__div {
  background-color: rgba(00, 00, 00, 0.7);
  max-width: 440px;
  border: #1fd5ac 1px solid;
  padding: 20px;
  position: absolute;
  border-radius: 10px;
  transition: all 0.3s ease-out;
}
.image__close__icon {
  float: right;
  margin-top: 20px;
  position: absolute;
  right: 30px;
  top: 10px;
  color: #1fd5ac;
  cursor: pointer;
}

h3 {
  font-size: 20px;
}

.hero__h3 {
  padding: 20px 0px;
  font-size: 24px;
  line-height: 40px;
}

.hero__h3 span {
  color: #1fd5ac;
  font-weight: 800;
  font-size: 30px;
}

.herosection__row {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}
.hero__cols {
  display: flex;
  justify-content: center;
  align-items: center;
}
.herosection__row h1.title {
  font-size: 55px;
  margin-bottom: 45px;
  line-height: 1.4;
  letter-spacing: -0.01em;
  width: 100%;
}

.herosection__row h1.title span {
  font-size: 55px;
  line-height: 1.4;
  letter-spacing: -0.01em;
}

.herosection__row .fire__img {
  width: 80px;
  justify-content: center;
  text-align: center;
  display: flex;
  margin: 0px auto;
  margin-bottom: 20px;
}
.herosection__row .shape__one {
  width: 200px;
  max-width: 100%;
  left: -23%;
  top: 40%;
  position: absolute;
  animation: 5s linear 1s infinite alternate mymove;
}

.hero__box {
  width: 100%;
  padding: 20px 0px;
  border-radius: 10px;
  margin: 5px 0px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(246, 246, 246, 0.06);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  padding: 20px 20px;
  margin-bottom: 15px;
}

/* HERO SECTION 2 CSS   */

.hero_col_wrap {
  background: transparent;
  border: 1px solid rgba(246, 246, 246, 0.06);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  display: flex;
  align-items: center;
  padding: 15px 15px;
  margin-bottom: 15px;
}

.hero__card1 {
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),
    rgba(255, 255, 255, 0.2); */
  background-image: url('../../assets/Images/NFT1-sml.png');
  background-position: center;
  background-size: cover;
  border: 1px solid rgba(246, 246, 246, 0.06);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.01);
  border-radius: 16px;
  padding: 10px 10px 20px 10px;
}
.hero__card2 {
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),
    rgba(255, 255, 255, 0.2); */
  background-image: url('../../assets/Images/NFT2-sml.png');
  background-position: center;
  background-size: cover;
  border: 1px solid rgba(246, 246, 246, 0.06);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.01);
  border-radius: 16px;
  padding: 10px 10px 20px 10px;
}
.hero__card3 {
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),
    rgba(255, 255, 255, 0.2); */
  background-image: url('../../assets/Images/NFT3-sml.png');
  background-position: center;
  background-size: cover;
  border: 1px solid rgba(246, 246, 246, 0.06);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.01);
  border-radius: 16px;
  padding: 10px 10px 20px 10px;
}

.hero__card h3 {
  color: #1fd5ac;
  font-size: 20px;
  padding: 10px 0px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5));
  border-radius: 10px;
}

.hero__card p {
  color: #fff !important;
  font-size: 16px;
  font-weight: 300;
  padding: 6px 5px;
  margin: 0px;
  border-bottom: 1px dashed #000;
}
.hero__card p:last-of-type {
  border-bottom: none !important;
}
.hero__counter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
}
.hero__counter .counter__dec {
  border-right: 1px solid #fff;
  padding: 5px 20px 8px 20px;
  cursor: pointer;
}
.hero__counter .counter__inc {
  border-left: 1px solid #fff;
  padding: 5px 20px 8px 20px;
  cursor: pointer;
}
.hero__counter .counter__inc:hover {
  background-color: #000;
  color: #1fd5ac;
  border-radius: 0px 10px 10px 0px;
}
.hero__counter .counter__dec:hover {
  background-color: #000;
  color: #1fd5ac;
  border-radius: 10px 0px 0px 10px;
}
.hero__mint__btn {
  user-select: none;
  -moz-user-select: none;
  background: #040e18;
  background-clip: border-box;
  border-radius: 70px;
  border: 1px solid transparent;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1;
  margin-bottom: 0;
  padding: 15px 50px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transition: all 0.4s 0s;
  vertical-align: middle;
  white-space: nowrap;
  box-shadow: 0px 6px 22px rgba(6, 34, 51, 0.22);
  background-clip: padding-box;
  position: relative;
}

.hero__mint__btn::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: -2px;
  background: linear-gradient(90deg, #1fd5ac, rgba(4, 12, 21, 1) 49%, #1fd5ac);
  border-radius: 70px;
  transition: all 0.4s ease 0s;
  z-index: -1;
}
.hero__mint__btn:hover {
  user-select: none;
  -moz-user-select: none;
  background: #040e18;
  background-clip: border-box;
  border-radius: 70px;
  border: 1px solid #1fd5ac;
  color: #1fd5ac;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1;
  margin-bottom: 0;
  padding: 15px 50px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transition: all 0.4s 0s;
  vertical-align: middle;
  white-space: nowrap;
  box-shadow: 0px 6px 22px rgba(6, 34, 51, 0.22);
  background-clip: padding-box;
  position: relative;
}
.hero__row2 {
  margin: 30px auto;
}
.hero__box2 {
  width: 100%;
  padding: 25px 0px;
  border-radius: 10px;
  margin: 15px 0px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(246, 246, 246, 0.06);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  align-items: center;
  justify-content: space-around;
  display: flex;
}
.hero__box2 h3 {
  font-weight: 300;
  font-size: 20px;
  padding: 0px;
  margin: 0px;
}
.hero__box2 h3 span {
  color: #1fd5ac;
}
.hero__box3 {
  width: 100%;
  padding: 20px 0px;
  border-radius: 10px;
  margin: 5px 0px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(246, 246, 246, 0.06);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  align-items: center;
  padding: 20px 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.hero__box3 div {
  display: flex;
  margin-left: 30px;
}
.hero__box3 h3 {
  font-weight: 300;
  font-size: 22px;
  padding: 10px 10px 20px 10px;
  margin: 0px;
}
.hero__box3 h3 span {
  color: #1fd5ac;
}

@keyframes mymove {
  from {
    left: -20%;
  }
  to {
    left: -14%;
  }
}

.herosection__row .shape__two {
  width: 80px;
  max-width: 100%;
  right: 0;
  top: 10%;
  position: absolute;
}

.herosection__row .shape__three {
  width: 170px;
  max-width: 100%;
  right: -14%;
  animation: 5s linear 1s infinite alternate touchdown;
  position: absolute;
}

@keyframes touchdown {
  from {
    top: 80%;
  }
  to {
    top: 100%;
  }
}

.herosection__row__timer {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  color: #fff;
}
.herosection__row__timer .coming-time {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.coming-time .time__count {
  min-width: 162px;
  min-height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  position: relative;
  margin: 20px 17.5px 0;
  font-size: 14px;
}

.herosection__row__timer .coming-time .time-count {
  min-width: 162px;
  min-height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  margin: 20px 17.5px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  background: #0b1d33;
  border: 1px solid rgba(255, 255, 255, 0.04);
  border-radius: 5px;
  color: #a4b4c3;
  text-transform: capitalize;
}
.herosection__row__timer .coming-time .time-count span {
  font-size: 35px;
  font-weight: 600;
  color: #1fd5ac;
  margin-bottom: 12px;
  display: block;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
}
.herosection__row__timer .coming-time .time-count.hour span {
  color: #ff9700;
}
.herosection__row__timer .coming-time .time-count.min span {
  color: #ff1d45;
}
.herosection__row__timer .coming-time .time-count.sec span {
  color: #12d176;
}

.banner-content .title {
  font-size: 55px;
  margin-bottom: 45px;
  line-height: 1.4;
  letter-spacing: -0.01em;
}
.banner-content .title span {
  color: #1fd5ac;
  font-size: 15px;
}
.banner-progress-wrap ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 50px;
}
.banner-progress-wrap ul li {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #1fd5ac;
  display: inline-block;
  position: relative;
  padding-bottom: 25px;
}
.banner-progress-wrap ul li::before {
  content: '';
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 14px;
  background: #1fd5ac;
}
.banner-progress-wrap ul li:nth-child(2) {
  color: #ff9700;
}
.banner-progress-wrap ul li:nth-child(3) {
  color: #12d176;
}
.banner-progress-wrap ul li:nth-child(2)::before {
  background: #ff9700;
}
.banner-progress-wrap ul li:nth-child(3)::before {
  background: #12d176;
}
.banner-progress-wrap {
  margin: 0 150px 70px;
}
.banner-progress-wrap .progress {
  height: 6px;
  background-color: #f2f2f2;
  border-radius: 0;
  overflow: inherit;
  margin-bottom: 25px;
  margin-top: 8px;
}
.banner-progress-wrap .progress .progress-bar {
  background-color: #1fd5ac;
  position: relative;
  overflow: inherit;
}
.banner-progress-wrap .progress .progress-bar::before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  z-index: 5;
  border: 6px solid #1fd5ac;
}
.banner-progress-wrap .title {
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.banner-progress-wrap .title span {
  margin-left: auto;
  font-size: 15px;
}
.banner-countdown-wrap .title {
  font-size: 26px;
  margin-bottom: 15px;
  letter-spacing: -0.01em;
}

.hero2__std__btn {
  padding: 10px 20px;
  background-color: transparent;
  background: #040e18;
  border-radius: 70px;
  border: 1px solid transparent;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 14px;
  cursor: pointer;
  margin: 8px auto;
  position: relative;
  font-weight: 700;
  min-width: 90%;
}
.hero2__std__btn::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: -2px;
  background: linear-gradient(90deg, #1fd5ac, rgba(4, 12, 21, 1) 49%, #1fd5ac);
  border-radius: 70px;
  transition: all 0.4s ease 0s;
  z-index: -1;
}
.hero2__std__btn:hover {
  user-select: none;
  -moz-user-select: none;
  background: #040e18;
  background-clip: border-box;
  border-radius: 70px;
  border: 1px solid #1fd5ac;
  color: #1fd5ac;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin: 8px auto;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transition: all 0.4s 0s;
  vertical-align: middle;
  white-space: nowrap;
  box-shadow: 0px 6px 22px rgba(6, 34, 51, 0.22);
  background-clip: padding-box;
  position: relative;
}
.bot__row1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 15px 0px;
  padding: 20px 0px;
  border-radius: 10px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(246, 246, 246, 0.06);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}

.bot__row2 h3,
.bot__row1 h3 {
  font-weight: 300;
  font-size: 20px;
  padding: 10px 10px 10px 10px;
  margin: 0px;
}
.bot__row1 h3 span,
.bot__row2 h3 span {
  color: #1fd5ac;
}

.bot__row2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 5px 0px 15px 0px;
  padding: 20px 0px;
  border-radius: 10px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(246, 246, 246, 0.06);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}

@media (max-width: 768px) {
  .herosection__row h1.title {
    font-size: 24px;
    margin-bottom: 25px;
    line-height: 1.4;
    letter-spacing: -0.01em;
    width: 100%;
    padding-left: 20px;
    text-align: center !important;
  }
  .herosection__row h1.title span {
    font-size: 24px;
    margin-bottom: 25px;
    line-height: 1.4;
    color: #1fd5ac;
    letter-spacing: -0.01em;
    width: 100%;
  }
  .herosection__container {
    padding: 50px 10px;
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
  }
  .herosection__row .shape__one {
    width: 130px;
    max-width: 100%;
    left: -23%;
    top: -5%;
    position: absolute;
    animation: 5s linear 1s infinite alternate mymove;
  }

  .hero2__std__btn {
    min-width: 200px;
    margin: 8px auto !important;
  }

  @keyframes mymove {
    from {
      left: 0%;
    }
    to {
      left: 5%;
    }
  }

  .herosection__row .shape__two {
    top: 3%;
  }
  .herosection__row .shape__three {
    left: -23%;
    top: 10%;
    display: none;
  }
  .banner-progress-wrap {
    margin: 20px 10px 50px 10px;
    text-align: center;
    justify-content: center;
  }
  .banner-progress-wrap h4.title span,
  .banner-progress-wrap h4.title {
    font-size: 12px;
  }
  .banner-progress-wrap ul {
    margin: 0 0;
  }

  .herosection__row__timer .coming-time .time-count {
    min-width: 90px;
    min-height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: relative;
    margin: 20px 15;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    background: #0b1d33;
    border: 1px solid rgba(255, 255, 255, 0.04);
    border-radius: 5px;
    color: #a4b4c3;
    text-transform: capitalize;
  }
  .herosection__row__timer .coming-time .time-count span {
    font-size: 28px;
    font-weight: 600;
    color: #1fd5ac;
    margin-bottom: 12px;
    display: block;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
  }

  .hero__box3 {
    width: 100%;
    padding: 20px 0px;
    border-radius: 10px;
    margin: 5px 0px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      rgba(255, 255, 255, 0.06);
    border: 1px solid rgba(246, 246, 246, 0.06);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    margin-bottom: 15px;
  }

  .hero__box2 h3,
  .hero__box3 h3 {
    font-weight: 300;
    font-size: 16px;
    padding: 15px 10px 20px 10px;
    margin: 0px;
    width: 100%;
    position: relative;
    display: inline-block;
  }
  .hero__box3 h3 span {
    color: #1fd5ac;
  }
  .mobi__np__nm {
    padding: 0px;
    margin: 0px;
  }

  .bot__row1 {
    display: flex;
    margin: 5px auto;
    align-items: center;
    justify-content: flex-start;
  }

  .bot__row2 h3,
  .bot__row1 h3 {
    font-weight: 300;
    font-size: 16px;
    padding: 10px 10px 20px 10px;
    margin: 0px;
  }
  .bot__row1 h3 span,
  .bot__row2 h3 span {
    color: #1fd5ac;
  }

  .bot__row2 {
    display: flex;
    margin: 15px auto;
    align-items: center;
    justify-content: flex-start;
  }
  .mobi__margin {
    margin: 10px auto;
  }
  .hero2__std__btn {
    margin: 5px 0px;
  }
}
