.road_bottom_col2 {
  padding: 80px 0px;
}
ul.roadmap__bullets {
  padding: 15px 15px;
  color: #fff;
}
ul.roadmap__bullets li {
  padding: 10px 10px;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  font-weight: 500;
}
