.sales__main__wrap {
  background-image: url('../../assets/Images/bg/chart_bg.jpg');
  background-position: center;
  background-attachment: fixed;
  padding: 100px;
}
.chart-inner {
  padding: 0 110px;
}
.chart-bg {
  background-image: url('./../../assets/Images/bg/chart_bg.jpg');
  background-size: cover;
  background-position: center;
  padding: 130px 0;
}
.chart-content .nav-tabs {
  border-bottom: none;
  margin: 0 -20px;
  margin-bottom: 50px;
}
.chart-content .nav-tabs .nav-item {
  padding: 0 20px;
}
.chart-content .nav-tabs .nav-link {
  margin-bottom: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 15px;
  color: #fff;
  font-weight: 700;
  padding: 0;
  position: relative;
}
.chart-content .nav-tabs .nav-link::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #00c4f4;
  transition: 0.3s linear;
  opacity: 0;
}
.chart-content .nav-tabs .nav-link.active::before {
  opacity: 1;
}
.chart-content {
  width: 72%;
}
.chart-content-inner .title {
  font-size: 55px;
  margin-bottom: 35px;
  letter-spacing: -0.01em;
  line-height: 1.2;
}
.chart-content-inner p {
  color: #a4b4c3;
  margin-bottom: 45px;
}
.chart-content-inner .btn {
  background: #00c4f4;
  min-width: 200px;
  padding: 21px 50px;
}
.chart-content-inner .btn:hover {
  color: #fff;
}
.chart-content-inner .btn::after {
  display: none;
}
.chart-wrap {
  background: #0b1d33;
  border-radius: 20px;
  padding: 60px 20px 60px;
  text-align: center;
}
.chart-wrap img {
  margin-bottom: 75px;
}
.chart-wrap ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.chart-wrap ul li {
  width: 50%;
  text-align: left;
  list-style: none;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  position: relative;
  padding-left: 30px;
  margin-bottom: 8px;
}
.chart-wrap ul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #005f73;
}
.chart-wrap ul li:nth-child(2):before {
  background: #f72585;
}
.chart-wrap ul li:nth-child(3):before {
  background: #5dd400;
}
.chart-wrap ul li:nth-child(4):before {
  background: #ff9700;
}
.chart-wrap ul li:nth-child(5):before {
  background: #00c4f4;
}
.chart-wrap ul li:nth-child(6):before {
  background: #007ff4;
}
