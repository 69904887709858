.slide-info-wrap {
  margin-bottom: 50px;
}
.slide-info-item {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}
/* .slide-info-item::before {
  content: '';
  position: absolute;
  right: -45px;
  top: 114px;
  background-image: url('../../assets/Images/images/line.png');
  width: 122px;
  height: 9px;
} */
.slide-info-wrap .row [class*='col-']:last-child .slide-info-item::before {
  display: none;
}
.slide-info-item .icon {
  width: 107px;
  height: 107px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
  font-size: 28px;
  color: #ff9700;
  margin: 0 auto;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.07);
  z-index: 1;
  margin-bottom: 10px;
}
.slide-info-item .icon-background {
  position: absolute;
  width: 83px;
  height: 83px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #0b1d33;
  border-radius: 50%;
  z-index: -1;
}
.slide-info-item .icon-background::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 83px;
  height: 83px;
  background: transparent;
  border: 3px solid #ff9700;
}
.slide-info-item .content p {
  color: #fff;
  font-size: 22px;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 30px;
}
.slide-info-wrap .row [class*='col-']:nth-child(2) .icon {
  color: #ff4581;
}
.slide-info-wrap .row [class*='col-']:nth-child(3) .icon {
  color: #00c4f4;
}
.slide-info-wrap .row [class*='col-']:nth-child(2) .icon-background::before {
  border-color: #ff4581;
}
.slide-info-wrap .row [class*='col-']:nth-child(3) .icon-background::before {
  border-color: #00c4f4;
}
