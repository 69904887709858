.rmtop__main__wrap {
  background-color: #030b15;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rmtop__row {
  display: flex;
  align-items: center;
  padding: 100px;
  text-align: center;
  position: relative;
}
.section__title {
  text-align: center;
  margin-bottom: 40px;
}
.rmtop__row .title {
  font-weight: 600;
  font-size: 42px;
  margin-bottom: 0;
  letter-spacing: -0.01em;
  margin-bottom: 30px;
}
.rmtop__row .title span {
  font-size: 42px;
  font-weight: 600;
  color: #00c4f4;
}

.rmtop__row h3 {
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  letter-spacing: 0.09em;
  display: inline-block;
  padding: 0 15px;
  margin-bottom: 25px;
}

.rmtop__row h3::before {
  content: '';
  margin-top: 5px;
  left: 0;
  background-color: #00c4f4;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  text-align: center;
  transform: translateY(-20%);
}
.rmtop__row h3::after {
  content: '';
  margin-top: 5px;
  right: 0;
  align-items: center;
  background-color: #00c4f4;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  transform: translateY(-20%);
}

.rmtop__row p {
  width: 70%;
  color: #a4b4c3;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.75;
}

.rmtop__us .float__img {
  position: absolute;
  left: 0px;
  top: 0px;
}
.dummy_col {
  background: #030b15;
  border: 1px solid #121a23;
  border-radius: 15px;
  padding: 60px 40px;
  transition: 0.3s ease-in-out;
  width: 300px;
  margin: 5px;
}

/* ROADMAP CSS */

.roadmap-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.roadmap-area::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(360deg, #0b1d33 0%, rgba(11, 29, 51, 0) 97.51%);
  transform: matrix(1, 0, 0, -1, 0, 0);
  opacity: 0.8;
}
.roadmap-wrap {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1670px;
  overflow-x: auto;
}
.roadmap-wrap::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1670px;
  height: 8px;
  background: linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);
  opacity: 0.1;
}
.roadmap-item {
  width: 345px;
  display: flex;
  flex-direction: column;
  flex: 0 0 345px;
}
.roadmap-title {
  display: inline-block;
  color: #00c4f4;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 22px;
  letter-spacing: 0.1em;
}
.roadmap-content .dot {
  content: '';
  position: absolute;
  left: -8px;
  top: 0;
  width: 16px;
  height: 16px;
  background: #00c4f4;
  border-radius: 50%;
}
.roadmap-content .dot::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
  background: #00c4f4;
  opacity: 0.1;
  border-radius: 50%;
}
.roadmap-content {
  position: relative;
  margin-left: 57px;
  padding-left: 17px;
  padding-top: 110px;
}
.roadmap-content::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background: #00c4f4;
}
.roadmap-content .title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
.roadmap-content p {
  margin-bottom: 0;
  color: #a4b4c3;
}
.roadmap-item:nth-child(even) {
  flex-direction: column-reverse;
  margin-bottom: 165px;
  width: 400px;
  flex: 0 0 400px;
}
.roadmap-item:nth-child(even) .roadmap-content .dot {
  top: auto;
  bottom: 0;
}
.roadmap-item:nth-child(even) .roadmap-title {
  margin-top: 22px;
  margin-bottom: 0;
}
.roadmap-item:nth-child(even) .roadmap-content {
  padding-bottom: 110px;
  padding-top: 0;
}
.roadmap-item:not(:first-child) {
  margin-left: -120px;
}
.roadmap-item:nth-child(odd) {
  margin-top: 165px;
  margin-left: -180px;
}
.roadmap-item:nth-child(1) {
  margin-left: 100px;
  width: 288px;
  flex: 0 0 auto;
}
.roadmap-item:last-child {
  width: 290px;
  flex: 0 0 auto;
}
.bt-roadmap-item:nth-child(2) .roadmap-content::before,
.bt-roadmap-item:nth-child(2) .dot,
.bt-roadmap-item:nth-child(2) .dot::before {
  background: #ff4581;
}
.bt-roadmap-item:nth-child(3) .roadmap-content::before,
.bt-roadmap-item:nth-child(3) .dot,
.bt-roadmap-item:nth-child(3) .dot::before {
  background: #ff9700;
}
.bt-roadmap-item:nth-child(4) .roadmap-content::before,
.bt-roadmap-item:nth-child(4) .dot,
.bt-roadmap-item:nth-child(4) .dot::before {
  background: #5dd400;
}
.bt-roadmap-item:nth-child(5) .roadmap-content::before,
.bt-roadmap-item:nth-child(5) .dot,
.bt-roadmap-item:nth-child(5) .dot::before {
  background: #007ff4;
}
.bt-roadmap-item:nth-child(6) .roadmap-content::before,
.bt-roadmap-item:nth-child(6) .dot,
.bt-roadmap-item:nth-child(6) .dot::before {
  background: #ff9700;
}
.bt-roadmap-item:nth-child(7) .roadmap-content::before,
.bt-roadmap-item:nth-child(7) .dot,
.bt-roadmap-item:nth-child(7) .dot::before {
  background: #ff4581;
}
.bt-roadmap-item:nth-child(2) .roadmap-title {
  color: #ff4581;
}
.bt-roadmap-item:nth-child(3) .roadmap-title {
  color: #ff9700;
}
.bt-roadmap-item:nth-child(4) .roadmap-title {
  color: #5dd400;
}
.bt-roadmap-item:nth-child(5) .roadmap-title {
  color: #007ff4;
}
.bt-roadmap-item:nth-child(6) .roadmap-title {
  color: #ff9700;
}
.bt-roadmap-item:nth-child(7) .roadmap-title {
  color: #ff4581;
}

/* roadmap-two */
.roadmap-wrap-two {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 50px;
}
.roadmap-wrap-two:last-child {
  margin-bottom: 0;
}
.roadmap-wrap-two::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  height: 8px;
  background: linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);
  opacity: 0.1;
}
.roadmap-wrap-two .roadmap-content .title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #030b15;
  line-height: 1.62;
}
.roadmap-wrap-two .roadmap-content p {
  color: #727885;
}
.roadmap-wrap-two .roadmap-content {
  padding-top: 75px;
}
.roadmap-wrap-two .roadmap-item:nth-child(odd) {
  margin-top: 0;
  margin-left: 0;
}
.roadmap-wrap-two .roadmap-item:not(:first-child) {
  margin-left: 0;
}
.roadmap-wrap-two .roadmap-item:nth-child(even) .roadmap-content {
  padding-bottom: 0;
  padding-top: 75px;
}
.roadmap-wrap-two .roadmap-item:nth-child(even) {
  flex-direction: column;
  margin-bottom: 30px;
  flex: 0 0 400px;
  width: 400px;
}
.roadmap-wrap-two .roadmap-item:nth-child(even) .roadmap-content .dot {
  top: 0;
  bottom: auto;
}
.roadmap-wrap-two .roadmap-item:nth-child(even) .roadmap-title {
  margin-top: 0;
  margin-bottom: 22px;
}
.roadmap-wrap-two .roadmap-item:nth-child(2),
.roadmap-wrap-two .roadmap-item:nth-child(1) {
  margin-left: 130px;
}
.roadmap-wrap-two .roadmap-item:nth-child(4) {
  width: 260px;
  flex: 0 0 260px;
}
.roadmap-wrap-two .roadmap-item {
  margin-bottom: 30px;
}
.roadmap-wrap-two .roadmap-item:nth-child(2) .roadmap-content::before,
.roadmap-wrap-two .roadmap-item:nth-child(2) .dot,
.roadmap-wrap-two .roadmap-item:nth-child(2) .dot::before {
  background: #ff9700;
}
.roadmap-wrap-two .roadmap-item:nth-child(3) .roadmap-content::before,
.roadmap-wrap-two .roadmap-item:nth-child(3) .dot,
.roadmap-wrap-two .roadmap-item:nth-child(3) .dot::before {
  background: #007ff4;
}
.roadmap-wrap-two .roadmap-item:nth-child(4) .roadmap-content::before,
.roadmap-wrap-two .roadmap-item:nth-child(4) .dot,
.roadmap-wrap-two .roadmap-item:nth-child(4) .dot::before {
  background: #ff4581;
}
.roadmap-wrap-two .roadmap-item:nth-child(2) .roadmap-title {
  color: #ff9700;
}
.roadmap-wrap-two .roadmap-item:nth-child(3) .roadmap-title {
  color: #007ff4;
}
.roadmap-wrap-two .roadmap-item:nth-child(4) .roadmap-title {
  color: #ff4581;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(1) .roadmap-content::before,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(1) .dot,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(1) .dot::before {
  background: #ff4581;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(2) .roadmap-content::before,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(2) .dot,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(2) .dot::before {
  background: #007ff4;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(3) .roadmap-content::before,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(3) .dot,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(3) .dot::before {
  background: #ff9700;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(4) .roadmap-content::before,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(4) .dot,
.roadmap-wrap-two.bottom .roadmap-item:nth-child(4) .dot::before {
  background: #00c4f4;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(1) .roadmap-title {
  color: #ff4581;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(2) .roadmap-title {
  color: #007ff4;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(3) .roadmap-title {
  color: #ff9700;
}
.roadmap-wrap-two.bottom .roadmap-item:nth-child(4) .roadmap-title {
  color: #00c4f4;
}

.bt-roadmap-wrap {
  display: flex;
  padding: 0;
  min-height: 490px;
  margin: 10px 0 0;
  justify-content: flex-end;
}
.bt-roadmap_x {
  position: relative;
}
.bt-roadmap_x::before {
  content: '';
  position: absolute;
  left: 0;
  top: calc(50% - 0px);
  transform: translateY(-50%);
  width: 100%;
  height: 8px;
  background: linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);
  opacity: 0.1;
}
.bt-roadmap-item {
  display: flex;
  width: 370px;
  flex: 0 0 auto;
  height: 310px;
  align-self: flex-end;
  flex-direction: column;
  justify-content: flex-start;
}
.bt-roadmap-item:nth-child(even) {
  align-self: flex-start;
  flex-direction: column-reverse;
  margin-top: -30px;
}
.bt-roadmap-item:nth-child(even) .roadmap-title {
  margin: 20px 0 0;
}
.roadmap-content span {
  display: block;
  color: #a4b4c3;
}
.bt-roadmap-item:nth-child(even) .roadmap-content {
  padding: 0 0 110px 17px;
}
.bt-roadmap-item:nth-child(even) .roadmap-content .dot {
  top: auto;
  bottom: 0;
}
.bt-roadmap-item:not(:first-child) {
  margin-left: -155px;
}
.bt-roadmap-item:last-child {
  width: 280px;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  display: none;
}
.bt-roadmap_x._mCS_1.mCS_no_scrollbar {
  padding-bottom: 0;
}
.bt-roadmap_x.mCustomScrollbar {
  padding: 0 0 30px;
}
.mCustomScrollBox
  + .mCSB_scrollTools
  + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
}
.bt-roadmap_x:not(.mCS_no_scrollbar):before {
  top: calc(50% - 15px);
}
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #00c2f2;
}
