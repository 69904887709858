.team__main__wrap {
  /* background: linear-gradient(360deg, #0b1d33 0%, rgba(11, 29, 51, 0) 97.51%); */
  background-color: #040e18;
  padding: 130px;
}
.text__center {
  text-align: center;
}
.title__container {
  margin: 0px auto 70px auto;
  position: relative;
}
.title__container h1 {
  font-size: 42px;
  margin-bottom: 0;
  letter-spacing: -0.01em;
}
.title__container h1 span {
  color: #00c4f4;
}

.title__container h3 {
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  letter-spacing: 0.09em;
  display: inline-block;
  padding: 0 15px;
  margin-bottom: 25px;
}

.title__container h3::before {
  content: '';
  margin-top: 5px;
  left: 0;
  background-color: #00c4f4;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  text-align: center;
  transform: translateY(-20%);
}
.title__container h3::after {
  content: '';
  margin-top: 5px;
  right: 0;
  align-items: center;
  background-color: #00c4f4;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  transform: translateY(-20%);
}

.image__col {
  align-items: center;
  text-align: center;
  padding-bottom: 50px;
}

.image__col h3 {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 600;
  transition: 0.3s linear;
}

.image__col p {
  font-weight: normal;
  font-size: 16px;
  color: #a4b4c3;
  font-style: normal;
  line-height: 1.75;
}
.team__thumb {
  position: relative;
  border-radius: 50%;
  padding: 15px;
  margin-bottom: 30px;
  display: inline-block;
  position: relative;
}
.team__thumb::before {
  content: '';
  left: 0;
  top: 0;
  width: 226px;
  height: 226px;
  background: transparent;
  border: 2px dashed #00c4f4;
  border-radius: 50%;
  position: absolute;
  transition: opacity 0.3s linear;
  animation: teamRotate 10s linear infinite;
}

.rotateme {
  -webkit-animation-name: teamRotate;
  animation-name: teamRotate;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes teamRotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes teamRotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.team-area {
  position: relative;
  z-index: 1;
}
.team-area::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, #0b1d33 0%, rgba(11, 29, 51, 0) 97.51%);
  transform: matrix(1, 0, 0, -1, 0, 0);
  z-index: -1;
}
.team-item {
  text-align: center;
  margin-bottom: 60px;
}
.team-item .team-thumb {
  position: relative;
  border-radius: 50%;
  padding: 15px;
  margin-bottom: 30px;
  display: inline-block;
}
.team-item .team-thumb::before {
  content: '';
  left: 0;
  top: 0;
  width: 226px;
  height: 226px;
  background: transparent;
  border: 2px dashed #00c4f4;
  border-radius: 50%;
  position: absolute;
  transition: opacity 0.3s linear;
  animation: teamRotate 10s linear infinite;
  opacity: 0;
}
.team-item:hover .team-thumb::before {
  opacity: 1;
}
.team-item .team-thumb img {
  border-radius: 50%;
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
}
.team-item:hover .team-thumb img {
  filter: grayscale(0);
}
.team-content .title {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
  transition: 0.3s linear;
}
.team-content span {
  display: block;
  margin-bottom: 10px;
}
.team-content .team-social {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0 -10px;
}
ul.team-social {
  padding: 0px 20px;
  text-align: center;
}
.team-content .team-social li {
  padding: 0 10px;
}
.team-content .team-social li a {
  color: #fff;
  font-size: 13px;
}
.team-content .team-social li a:hover {
  color: #00c4f4;
}
