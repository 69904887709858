.footer-area {
  position: relative;
  z-index: 1;
  background-color: #030b15;
}
.footer-area::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    359.43deg,
    #0b1d33 11.06%,
    rgba(11, 29, 51, 0) 99.43%
  );
  z-index: -1;
}
.footer-scroll-wrap {
  position: relative;
  z-index: 1;
}
.footer-scroll-wrap .scroll-to-target {
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #030b15;
  border: 1px solid #1f262f;
  border-radius: 50%;
  font-size: 18px;
  padding: 0 0;
  color: #fff;
  margin: 0 auto;
}
.footer-scroll-wrap .scroll-to-target:hover {
  background: #00c4f4;
  border-color: #00c4f4;
}
.footer-scroll-wrap::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
  background: #1f262f;
  z-index: -1;
}
.footer-top {
  padding: 55px 0 50px;
}
.footer-widget {
  margin-bottom: 30px;
}
.footer-widget .f-logo {
  margin-bottom: 20px;
  display: block;
}
.footer-content p {
  margin-bottom: 25px;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  color: #a4b4c3;
}
.footer-content .footer-social {
  display: flex;
  align-items: center;
  margin: 0 -7px;
  padding-left: 0px;
}

.footer-content .footer-social li {
  padding: 0 7px;
  list-style: none;
}
.footer-content .footer-social li a {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #719ed6;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
}
.footer-content .footer-social li a:hover {
  background: #00c4f4;
  border-color: #00c4f4;
}
.footer-widget .fw-title {
  font-size: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 25px 0 27px;
}
ul {
  padding-left: 0px !important;
  text-decoration: none !important;
}
.footer-link ul li {
  margin-bottom: 10px;
  list-style: none;
}
.footer-link ul li:last-child {
  margin-bottom: 0;
}
.footer-link ul li a {
  font-size: 15px;
  line-height: 26px;
  color: #a4b4c3;
  font-weight: 500;
  text-decoration: none;
  position: relative;
}
.footer-link ul li a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}
.footer-link ul li a:hover::before {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.footer-link ul li a:hover {
  color: #fff;
}
.footer-top .row [class*='col-']:nth-child(2) .footer-widget {
  margin-left: 80px;
}
.footer-top .row [class*='col-']:nth-child(4) .footer-widget {
  margin-left: 75px;
}
.footer-newsletter p {
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 60px;
  color: #a4b4c3;
}
.footer-newsletter form {
  position: relative;
}
.footer-newsletter form input {
  width: 100%;
  border: none;
  background: rgb(118 118 130 / 10%);
  border-radius: 10px;
  font-weight: 400;
  font-size: 15px;
  padding: 23px 80px 23px 25px;
  color: #fff;
  height: 72px;
}
.footer-newsletter form input::placeholder {
  font-weight: 400;
  font-size: 15px;
  color: #fff;
}
.footer-newsletter form button {
  position: absolute;
  right: 0;
  top: 0;
  width: 65px;
  height: 72px;
  background: #00c4f4;
  border-radius: 10px;
  font-size: 20px;
  color: #fff;
  border: none;
}
.footer-bottom {
  border-top: 1px solid rgb(255 255 255 / 6%);
  padding: 27px 0;
}
.copyright-text p {
  color: #fff;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
}
.footer-menu ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 -30px;
}
.footer-menu ul li {
  padding: 0 30px;
}
.footer-menu ul li a {
  font-size: 15px;
  color: #a4b4c3;
  text-decoration: none;
  font-weight: 500;
}
.footer-menu ul li a:hover {
  color: #fff;
}

/* footer-two */
.footer-area-two .footer-top {
  padding: 70px 40px 65px;
}
.footer-menu-two .navigation {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.footer-menu-two .navigation li {
  margin-right: 60px;
}
.footer-menu-two .navigation li:last-child {
  margin-right: 0;
}
.footer-menu-two .navigation li a {
  color: #030b15;
  font-weight: 500;
  font-size: 16px;
}
.footer-menu-two .navigation li a:hover {
  color: #00c4f4;
}
.footer-area-two .footer-social ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-area-two .footer-social ul li {
  margin-right: 40px;
}
.footer-area-two .footer-social ul li:last-child {
  margin-right: 0;
}
.footer-area-two .footer-social ul li a {
  color: #0a142f;
  opacity: 0.3;
  font-size: 24px;
  line-height: 1;
}
.footer-area-two .footer-social ul li a:hover {
  color: #00c4f4;
  opacity: 1;
}
.footer-area-two .footer-bottom {
  border-top: 1px solid #f0f3f9;
  padding: 40px 40px;
}
.footer-area-two .copyright-text p {
  color: #282f3b;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
}
.footer-area-two .scroll-up .scroll-to-target {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #564dca;
  padding: 0 0;
  border: none;
  color: #fff;
  font-size: 17px;
  margin: -60px auto 15px;
}
.footer-area-two .scroll-up .scroll-to-target:hover {
  background: #00c4f4;
}
.footer-area-two .scroll-up span {
  display: block;
  color: #0a142f;
  font-size: 14px;
  font-weight: 500;
}
.footer-bottom-menu ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-bottom-menu ul li {
  margin-right: 30px;
}
.footer-bottom-menu ul li:last-child {
  margin-right: 0;
}
.footer-bottom-menu ul li a {
  font-size: 15px;
  color: #0a142f;
  font-weight: 500;
}
.footer-bottom-menu ul li a:hover {
  color: #00c4f4;
}

/* footer-three */
.footer-bg {
  background-image: url(./../../assets/Images/bg/breadcrumb-bg.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 120px;
}
.footer-bg::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background-image: url(./../../assets/Images/bg/footer_shape.png);
  background-repeat: repeat;
  background-position: center;
  width: 100%;
  height: 29px;
}
.footer-area-three .footer-top {
  padding: 0 0 50px;
}

.bt-roadmap-wrap {
  display: flex;
  padding: 0;
  min-height: 490px;
  margin: 10px 0 0;
  justify-content: flex-end;
}
.bt-roadmap_x {
  position: relative;
}
.bt-roadmap_x::before {
  content: '';
  position: absolute;
  left: 0;
  top: calc(50% - 0px);
  transform: translateY(-50%);
  width: 1670px;
  height: 8px;
  background: linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);
  opacity: 0.1;
}
.bt-roadmap-item {
  display: flex;
  width: 370px;
  flex: 0 0 auto;
  height: 305px;
  align-self: flex-end;
  flex-direction: column;
  justify-content: flex-start;
}
.bt-roadmap-item:nth-child(even) {
  align-self: flex-start;
  flex-direction: column-reverse;
  margin-top: -10px;
}
.bt-roadmap-item:nth-child(even) .roadmap-title {
  margin: 22px 0 0;
}
.roadmap-content span {
  display: block;
  color: #a4b4c3;
}
.bt-roadmap-item:nth-child(even) .roadmap-content {
  padding: 0 0 110px 17px;
}
.bt-roadmap-item:nth-child(even) .roadmap-content .dot {
  top: auto;
  bottom: 0;
}
.bt-roadmap-item:not(:first-child) {
  margin-left: -155px;
}
.bt-roadmap-item:last-child {
  width: 280px;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  display: none;
}
.bt-roadmap_x._mCS_1.mCS_no_scrollbar {
  padding-bottom: 0;
}
.bt-roadmap_x.mCustomScrollbar {
  padding: 0 0 30px;
}
.mCustomScrollBox
  + .mCSB_scrollTools
  + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
}
.bt-roadmap_x:not(.mCS_no_scrollbar):before {
  top: calc(50% - 15px);
}
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #00c2f2;
}

/* 21. Preloader */
#preloader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #030b15;
  z-index: 999;
  width: 100%;
  height: 100%;
}
.spinner {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}
.spinner > div {
  background-color: #00c2f2;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
