.roadmap__main__wrap {
  background-color: #040e18;
  background-image: url('../../assets/Images/bg/area_bg.png');
  background-position: center;
  background-repeat: no-repeat;
}

.roadmap__row h1 {
  font-size: 42px;
  margin-bottom: 0;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.roadmap__row h1 span {
  color: #00c4f4;
}
.roadmap__row h3 {
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  letter-spacing: 0.09em;
  display: inline-block;
  padding: 0 15px;
  margin-bottom: 25px;
  text-align: center !important;
}

.roadmap__row h3::before {
  content: '';
  margin-top: 5px;
  left: 0;
  background-color: #00c4f4;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-20%);
}

.roadmap__row h3::after {
  content: '';
  margin-top: 5px;
  right: 0;
  align-items: center;
  background-color: #00c4f4;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  transform: translateY(-20%);
}
