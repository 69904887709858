a {
  text-decoration: none;
}
.connect__btn {
  user-select: none;
  -moz-user-select: none;
  background: #040e18;
  background-clip: border-box;
  border-radius: 70px;
  border: 1px solid transparent;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 0;
  padding: 15px 50px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transition: all 0.4s 0s;
  vertical-align: middle;
  white-space: nowrap;
  box-shadow: 0px 6px 22px rgba(6, 34, 51, 0.22);
  background-clip: padding-box;
  position: relative;
}

.connect__btn::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: -2px;
  background: linear-gradient(90deg, #1fd5ac, rgba(4, 12, 21, 1) 49%, #1fd5ac);
  border-radius: 70px;
  transition: all 0.4s ease 0s;
  z-index: -1;
}
.connect__btn:hover {
  user-select: none;
  -moz-user-select: none;
  background: #040e18;
  background-clip: border-box;
  border-radius: 70px;
  border: 1px solid #1fd5ac;
  color: #1fd5ac;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 0;
  padding: 15px 50px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transition: all 0.4s 0s;
  vertical-align: middle;
  white-space: nowrap;
  box-shadow: 0px 6px 22px rgba(6, 34, 51, 0.22);
  background-clip: padding-box;
  position: relative;
}

.btn.btn-two {
  background: #564dca;
  border-radius: 5px;
  padding: 25px 42px;
  border: none;
}
.btn.btn-two::after {
  display: none;
}
.btn.btn-two:hover {
  color: #fff;
  background: #1fd5ac;
}
.breadcrumb > .active {
  color: #888;
}

@media (max-width: 767.98px) {
  .btn {
    user-select: none;
    -moz-user-select: none;
    background: transparent;
    border-radius: 70px;
    border: 1px solid transparent;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-bottom: 0;
    padding: 10px 20px !important;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: all 0.4s 0s;
    white-space: nowrap;
    box-shadow: 0px 6px 22px rgba(6, 34, 51, 0.22);
    background-clip: padding-box;
    position: relative;
    border-color: #fff;
    width: 100px;
  }
}
