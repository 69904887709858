@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
body {
  font-family: 'Outfit', sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #fff;
  font-style: normal;
  background-color: #030b15;
  line-height: 1.75;
  margin: 0;
  background-color: #eee;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  margin-top: 0px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
.highcharts-credits {
  display: none !important;
}
