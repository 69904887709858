.whyus__main__wrap {
  background-color: #030b15;
  padding: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.whyus__row {
  display: flex;
  align-items: center;
  padding: 30px;
  position: relative;
}
.section__title {
  text-align: center;
  margin-bottom: 40px;
}
.whyus__row .title {
  font-weight: 600;
  font-size: 42px;
  margin-bottom: 0;
  letter-spacing: -0.01em;
  margin-bottom: 30px;
}
.whyus__row .title span {
  font-size: 42px;
  font-weight: 600;
  color: #00c4f4;
}

.whyus__row h3 {
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  letter-spacing: 0.09em;
  display: inline-block;
  padding: 0 15px;
  margin-bottom: 25px;
}

.whyus__row h3::before {
  content: '';
  margin-top: 5px;
  left: 0;
  background-color: #00c4f4;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-20%);
}
.whyus__row h3::after {
  content: '';
  margin-top: 5px;
  right: 0;
  align-items: center;
  background-color: #00c4f4;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  transform: translateY(-20%);
}

.whyus__row p {
  width: 70%;
  color: #a4b4c3;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.75;
}

.whyus__us .float__img {
  position: absolute;
  left: 0px;
  top: 0px;
}
.dummy_col {
  background: #030b15;
  border: 1px solid #121a23;
  border-radius: 15px;
  padding: 60px 40px;
  transition: 0.3s ease-in-out;
  width: 300px;
  margin: 5px;
  min-height: 350px;
}
.dummy_col h4 {
  font-size: 16px;
  line-height: 30px;
  color: #a4b4c3;
}

.whyus-info-wrap {
  margin-bottom: 50px;
}
.whyus-info-item {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}
.whyus-info-item::before {
  content: '';
  position: absolute;
  right: -61px;
  top: 44px;
  background-image: url('../../assets/Images/images/line.png');
  width: 122px;
  height: 9px;
}
.whyus-info-wrap .row [class*='col-']:last-child .whyus-info-item::before {
  display: none;
}
.whyus-info-item .icon {
  width: 107px;
  height: 107px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
  font-size: 28px;
  color: #ff9700;
  margin: 0 auto;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.07);
  z-index: 1;
  margin-bottom: 10px;
}
.whyus-info-item .icon-background {
  position: absolute;
  width: 83px;
  height: 83px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #0b1d33;
  border-radius: 50%;
  z-index: -1;
}
.whyus-info-item .icon-background::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 83px;
  height: 83px;
  background: transparent;
  border: 3px solid #ff9700;
}
.whyus-info-item .content p {
  color: #fff;
  font-size: 22px;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 30px;
}
.whyus-info-wrap .row [class*='col-']:nth-child(2) .icon {
  color: #ff4581;
}
.whyus-info-wrap .row [class*='col-']:nth-child(3) .icon {
  color: #00c4f4;
}
.whyus-info-wrap .row [class*='col-']:nth-child(2) .icon-background::before {
  border-color: #ff4581;
}
.whyus-info-wrap .row [class*='col-']:nth-child(3) .icon-background::before {
  border-color: #00c4f4;
}

@media (max-width: 767.98px) {
  .whyus__main__wrap {
    background-color: #030b15;
    padding: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .whyus-info-item::before {
    display: none;
  }
  .whyus__row {
    padding: 0px;
  }
  .whyus__row .title {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 0;
    letter-spacing: -0.01em;
    margin-bottom: 30px;
    line-height: 50px;
  }
  .whyus__row .title span {
    font-size: 30px;
    font-weight: 600;
    color: #00c4f4;
  }

  .slick-next:before {
    margin-left: -20px;
  }
  .slick-prev:before {
    margin-right: -20px;
  }
}
