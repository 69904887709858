.contact__main__wrap {
  background-color: #030b15;
  padding: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.contact__row {
  display: flex;
  align-items: center;
  padding: 30px;
  position: relative;
}
.section__title {
  text-align: center;
  margin-bottom: 40px;
}
.contact__row .title {
  font-weight: 600;
  font-size: 42px;
  margin-bottom: 0;
  letter-spacing: -0.01em;
  margin-bottom: 30px;
}
.contact__row .title span {
  font-size: 42px;
  font-weight: 600;
  color: #00c4f4;
}

.contact__row h3 {
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  letter-spacing: 0.09em;
  display: inline-block;
  padding: 0 15px;
  margin-bottom: 25px;
}

.contact__row h3::before {
  content: '';
  margin-top: 5px;
  left: 0;
  background-color: #00c4f4;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-20%);
}
.contact__row h3::after {
  content: '';
  margin-top: 5px;
  right: 0;
  align-items: center;
  background-color: #00c4f4;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  transform: translateY(-20%);
}

.contact-info-wrap {
  margin-bottom: 50px;
}
.contact-info-item {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}
.contact-info-item::before {
  content: '';
  position: absolute;
  right: -61px;
  top: 44px;
  background-image: url('../../assets/Images/images/line.png');
  width: 122px;
  height: 9px;
}
.contact-info-wrap .row [class*='col-']:last-child .contact-info-item::before {
  display: none;
}
.contact-info-item .icon {
  width: 107px;
  height: 107px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
  font-size: 28px;
  color: #ff9700;
  margin: 0 auto;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.07);
  z-index: 1;
  margin-bottom: 10px;
}
.contact-info-item .icon-background {
  position: absolute;
  width: 83px;
  height: 83px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #0b1d33;
  border-radius: 50%;
  z-index: -1;
}
.contact-info-item .icon-background::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 83px;
  height: 83px;
  background: transparent;
  border: 3px solid #ff9700;
}
.contact-info-item .content p {
  color: #fff;
  font-size: 22px;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 30px;
}
.contact-info-wrap .row [class*='col-']:nth-child(2) .icon {
  color: #ff4581;
}
.contact-info-wrap .row [class*='col-']:nth-child(3) .icon {
  color: #00c4f4;
}
.contact-info-wrap .row [class*='col-']:nth-child(2) .icon-background::before {
  border-color: #ff4581;
}
.contact-info-wrap .row [class*='col-']:nth-child(3) .icon-background::before {
  border-color: #00c4f4;
}
.contact-form-wrap {
  background-image: url('../../assets/Images/bg/contact_bg.png');
  background-position: center;
  background-size: cover;
  padding: 80px;
}
.contact-form-wrap .form-grp {
  margin-bottom: 30px;
}
.contact-form-wrap .form-grp textarea,
.contact-form-wrap .form-grp input {
  width: 100%;
  background: #0b1d33;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  padding: 18px 20px;
  height: 65px;
}
.contact-form-wrap .form-grp textarea::placeholder,
.contact-form-wrap .form-grp input::placeholder {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  opacity: 0.7;
}
.contact-form-wrap .form-grp textarea {
  min-height: 184px;
  max-height: 184px;
}
.contact-form-wrap .submit-btn .btn {
  background: #0b1d33;
  border-color: #00c4f4;
}
